<template>
  <div class="d-flex flex-column flex-grow-1 mr-2 mb-2 rounded">
    <BaseToolBar title="Utilisateurs" subtitle="Droits d'accès des utilisateurs"></BaseToolBar>
    <users-selector
      :loading="loading"
      :selected="users"
      @autorize="autorize"
      @unautorize="unautorize"
    ></users-selector>
  </div>
</template>
<script>
import UsersSelector from "@/components/application/UsersSelector.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";

import { mapFields } from "vuex-map-fields";
import { mapMultiRowFields } from "vuex-map-fields";
import { mapActions } from "vuex";

export default {
  components: { UsersSelector, BaseToolBar },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    ...mapActions({
      _autorize: "clients/autorizeUser",
      _unautorize: "clients/unautorizeUser"
    }),
    autorize: function(data) {
      this.feedback = {};
      this.loading = true;
      this._autorize({
        user_id: data.id,
        client_id: this.$route.params.id
      })
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    unautorize: function(data) {
      this.feedback = {};
      this.loading = true;
      this._unautorize({
        user_id: data.id,
        client_id: this.$route.params.id
      })
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    }
  },
  mounted() {},
  computed: {
    ...mapFields("clients", ["client.id"]),
    ...mapMultiRowFields("clients", ["users"])
  }
};
</script>
<style lang="css">
</style>